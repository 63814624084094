import { apolloClient } from "../graphql/apolloClient";

import {
  UPDATE_CONFIG_DOMAIN,
  FETCH_CONFIG_DOMAIN,
  FETCH_CONFIG_DOMAINS
} from "../graphql/configDomain";

export function fetchConfigDomainsApi({token, params}) {
  return apolloClient.query({
    query: FETCH_CONFIG_DOMAINS,
    variables: params
  });
}

export function fetchConfigDomainApi({ params }) {
  return apolloClient.query({
    query: FETCH_CONFIG_DOMAIN,
    variables: params,
  });
}

// export function addDomainApi({ token, params }) {
//   return apolloClient.mutate({
//     mutation: ADD_DOMAIN,
//     variables: params,
//   });
// }

export function updateConfigDomainApi({ token, params }) {
  return apolloClient.mutate({
    mutation: UPDATE_CONFIG_DOMAIN,
    variables: params,
  });
}

// export function deleteDomainApi({ token, params }) {
//   return apolloClient.mutate({
//     mutation: DELETE_DOMAIN,
//     variables: params,
//   });
// }
