// import request from './axiosServices';

import { apolloClient } from "../graphql/apolloClient";
import { GET_BOOKS } from "../graphql/userAmin";

export * from "./users";
export * from "./matchesDate";
export * from "./competitions";
export * from "./teams";
export * from "./leagues";
export * from "./entriesData";
export * from "./general";
export * from "./historyPlan";
export * from "./payments";
export * from "./entriesSetting";
export * from "./domain";
export * from "./configDomain";

export function fetchUserAdminListApi(token, params) {
  return apolloClient.query({
    query: GET_BOOKS
  });
}

