import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  entry: {},
  entries: [],
  entriesRecentlyRead: [],
  entriesSearch: [],
  entriesBookMark: [] 
};

const entriesSlice = createSlice({
  name: "entries",
  initialState,
  reducers: {
    fetchEntriesList(state, action) {
    },
    fetchEntriesListSuccess(state, action) {
      state.entries = action.payload
    },

    addEntriesSettingListSuccess(state, action){
      const index = state.entries?.docs.findIndex(i => i?._id === action?.payload?.entryId);

      if(index > -1){
        state.entries.docs[index].entries_setting[0] = action.payload;
      }
    }
  }
});


// Action 
export const { 
  fetchEntriesList,
  fetchEntriesListSuccess,
  addEntriesSettingListSuccess
} = entriesSlice.actions;

// Reducer
const entriesDataReducer = entriesSlice.reducer;

export default entriesDataReducer;
