import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { useQuery } from "react-query";
import toast from "react-hot-toast";

import { fetchEntriesDataApi } from "../api";
import { fetchEntriesData, fetchEntriesDataSuccess } from "../redux/slices/entriesDataSlice";

function* fetchEntriesDataSaga(action) {
  try {
    const res = yield call(fetchEntriesDataApi, { params: action.payload });

    if (res?.data) {
      yield put(fetchEntriesDataSuccess(res?.data?.entriesData));
    }
  } catch (error) {
    console.log(error.message);
    toast.error("Get data errors !");
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchEntriesData.type, fetchEntriesDataSaga);
}
