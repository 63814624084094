import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showLoading(state, action) {
      state.loading = action.payload || true;
    },
    hideLoading(state, action) {
      state.loading = action.payload || false;
    },
  },
  
  extraReducers: {
    // [HYDRATE]: (state, action) => {
    //   if(!action.payload.loading.data){
    //     return state;
    //   }
    //   state.loading = action.payload.loading.data
    // }
  }
});


// Action 
export const {
  showLoading,
  hideLoading
} = commonSlice.actions;

// Reducer
const commonReducer = commonSlice.reducer;

export default commonReducer;
