import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import {
  createCommentApi,
  fetchCommentsApi,
  fetchReplyApi,
  updateCommentApi,
  deleteCommentApi
} from '../api/comments';

// import { successToast, errorToast, failedToast } from '../helpers';
import { showLoading, hideLoading } from '../redux/slices/commonSlice';
import {
  createCommentSuccess,
  createComment,
  fetchComments,
  fetchCommentsSuccess,
  fetchReply,
  fetchReplySuccess,
  updateComment,
  updateCommentSuccess,
  deleteComment,
  deleteCommentSuccess
} from '../redux/slices/commentsSlice';

function* createCommentSaga(action) {
  try {
    const res = yield call(createCommentApi, { params: action.payload });

    const { data, errors } = res.data;

    if (data.createComment?.ok) {
      yield put(createCommentSuccess(data?.createComment));
      toast.success('Comment success, please await approval.');
    } else {
    }
  } catch (error) {
    yield put(hideLoading(false));
    toast.error(error?.message);
  }
}

function* updateCommentSaga(action) {
  try {
    const res = yield call(updateCommentApi, {
      token: action.payload?.token,
      params: action.payload,
    });

    const { data, errors } = res.data;

    if (data?.updateComment?.ok) {
      toast.success('Success');
      yield put(updateCommentSuccess(data.updateComment));
    } else {
      toast.error(`${errors[0].message}`);
    }
  } catch (error) {
    yield put(hideLoading(false));
    toast.error(error?.message);
  }
}

function* fetchCommentsSaga(action) {
  try {
    const res = yield call(fetchCommentsApi, {
      token: action.payload?.token,
      params: action.payload,
    });

    const { data, errors } = res.data;

    yield put(fetchCommentsSuccess(data?.comments));
  } catch (error) {
    toast.error(error?.message);
  }
}

function* fetchReplySaga(action) {
  try {
    const res = yield call(fetchReplyApi, {
      token: action.payload?.token,
      params: action.payload,
    });

    const { data, errors } = res.data;

    yield put(fetchReplySuccess(data?.comments));
  } catch (error) {
    toast.error(error?.message);
  }
}

function* deleteCommentSaga(action) {
  try {
    const res = yield call(deleteCommentApi, { params: action.payload });

    const { data, errors } = res.data;
    if (data.deleteComment?.ok) {
      toast.success("Deleted Success");
      yield put(deleteCommentSuccess(data?.deleteComment));
    } else {
      if (data.deleteComment?.error) {
        toast.error(`${data.deleteComment?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
export default function* reportSaga() {
  yield takeLatest(createComment.type, createCommentSaga);
  yield takeLatest(updateComment.type, updateCommentSaga);
  yield takeLatest(deleteComment.type, deleteCommentSaga);
  yield takeEvery(fetchComments.type, fetchCommentsSaga);
  yield takeEvery(fetchReply.type, fetchReplySaga);
}
