import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import {
  fetchEntriesList,
  fetchEntriesListSuccess,
} from '../redux/slices/entriesSlice';
import { fetchEntriesListApi } from '../api/entries';
import { showLoading, hideLoading } from '../redux/slices/commonSlice';

function* fetchEntriesListSaga(action) {
  try {
    if (action?.payload?.inputs?.type !== 'search') {
      yield put(showLoading('loading-entries-data'));
    }
    const res = yield call(fetchEntriesListApi, {
      token: '',
      params: action.payload,
    });

    const { entries } = res.data.data;

    if (res.data.data) {
      yield put(hideLoading(false));
      if (action?.payload?.inputs?.type === 'array_get') {
        yield put(fetchRecentlyReadSuccess(entries));
      // } else if (action?.payload?.inputs?.type === 'search') {
        // yield put(fetchSearchSuccess(entries));
      } else if (action?.payload?.inputs?.type === 'bookmarks') {
        yield put(fetchEntriesBookMarkSuccess(entries));
      } else {
        yield put(fetchEntriesListSuccess(entries));
      }
    } else {
      toast.error('Fetch data errors.');
      yield put(hideLoading(false));
    }
  } catch (error) {
    console.log(error);
    toast.error(error, error.message);
    yield put(hideLoading(false));
  }
}

// function* fetchEntriesByIdSaga(action) {
//   try {
//     yield put(showLoading("loading-entry"));
//     const res = yield call(fetchEntriesByIdApi,  { token: '', params: action.payload });

//     const { entry } = res.data.data;
//     if(res.data.data){
//       yield put(hideLoading(false));
//       yield put(fetchEntriesByIdSuccess(entry));
//     }else {
//       errorToast("Fetch data errors.");
//       yield put(hideLoading(false));
//     }
//   } catch (error) {
//     console.log(error)
//     failedToast(error, error.message);
//     yield put(hideLoading(false));
//   }
// }

export default function* reportSaga() {
  yield takeEvery(fetchEntriesList.type, fetchEntriesListSaga);
}
