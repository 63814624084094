import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

const initialState = {
  comments: Array([]),
  reply: [],
  comment: {},
};

const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    fetchComments(state, action) {
      // state.comments = [];
    },
    fetchCommentsSuccess(state, action) {
      state.comments = action.payload;
    },

    fetchReply(state, action) {},

    fetchReplySuccess(state, action) {
      state.reply = action.payload;
    },

    createComment(state, action) {},
    createCommentSuccess(state, action) {
      // const index = state.comments.findIndex((i) => i.id === action.payload?.data?.id);
      // if (index !== -1) {
      //   state.comments[index] = action.payload.data;
      // }
      // state.comments.docs =[...state.comments.docs,  action.payload.data];
    },

    updateComment(state, action) {},
    updateCommentSuccess(state, action) {
      const index = state.comments?.docs.findIndex(
        (i) => i.id === action.payload?.data?.id
      );
      if (index !== -1) {
        state.comments.docs[index] = action.payload.data;
      }
      state.comment = action.payload.data;
    },

    deleteComment(state, action) {
    },

    deleteCommentSuccess(state, action) {
      state.comments.docs = state?.comments?.docs?.filter(item => item.id !== action.payload.data.id);
    }
  },

  extraReducers: {
    // [HYDRATE]: (state, action) => {
    //   if(!action.payload.loading.data){
    //     return state;
    //   }
    //   state.loading = action.payload.loading.data
    // }
  },
});

// Action
export const {
  createComment,
  createCommentSuccess,
  updateComment,
  updateCommentSuccess,
  fetchComments,
  fetchCommentsSuccess,
  fetchReply,
  fetchReplySuccess,
  deleteComment,
  deleteCommentSuccess
} = commentSlice.actions;

// Reducer
const commentReducer = commentSlice.reducer;

export default commentReducer;
