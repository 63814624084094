import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  addDomainApi,
  fetchDomainsApi,
  fetchDomainByIdApi,
  updateDomainApi,
  deleteDomainApi
} from "../api";

import { 
  addDomain,
  addDomainSuccess,
  fetchDomains,
  fetchDomainsSuccess,
  updateDomain,
  updateDomainSuccess,
  deleteDomain,
  deleteDomainSuccess,
  fetchDomainById,
  fetchDomainByIdSuccess
 } from "../redux/slices/domainSlice";

function* fetchDomainsSaga(action) {
  try {
    const res = yield call(fetchDomainsApi, { params: action.payload });
    yield put((fetchDomainsSuccess(res?.data?.domains)));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* fetchDomainByIdSaga(action) {
  try {
    const res = yield call(fetchDomainByIdApi, { params: action.payload });
    yield put(fetchDomainByIdSuccess(res?.data?.domain));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* addDomainSaga(action) {
  try {
    const res = yield call(addDomainApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data?.addDomain?.ok) {
      toast.success("Created success");
      yield put(addDomainSuccess(data?.addDomain?.data));
    } else {
      if (data.addDomain?.error) {
        toast.error(`${data.addDomain?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}


function* deleteDomainSaga(action) {
  try {
    const res = yield call(deleteDomainApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.deleteDomain?.ok) {
      toast.success("Deleted Success");
      yield put(deleteDomainSuccess(data?.deleteDomain?.data));

    } else {
      if (data.deleteDomain?.error) {
        toast.error(`${data.deleteDomain?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
function* updateDomainSaga(action) {
  try {
    const res = yield call(updateDomainApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.updateDomain?.ok) {
      toast.success("Updated Success");
      yield put(updateDomainSuccess(data?.updateDomain?.data));
    } else {
      if (data.updateDomain?.error) {
        toast.error(`${data.updateDomain?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchDomains.type, fetchDomainsSaga);
  yield takeEvery(fetchDomainById.type, fetchDomainByIdSaga);
  yield takeLatest(addDomain.type, addDomainSaga);
  yield takeLatest(deleteDomain.type, deleteDomainSaga);
  yield takeLatest(updateDomain.type, updateDomainSaga);
}
