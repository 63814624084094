import request from './axiosServices';

import { 
  URL_DELETE_COMMENT,
  URL_CREATE_COMMENT,
  URL_FETCH_COMMENTS,
  URL_UPDATE_COMMENT,
  URL_FETCH_REPLY
} from '../graphql/comments';

export function createCommentApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: URL_CREATE_COMMENT,
      variables: params,
    },
    token
  });
}

export function updateCommentApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: URL_UPDATE_COMMENT,
      variables: params,
    },
    token
  });
}

export function fetchCommentsApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: URL_FETCH_COMMENTS,
      variables: params,
    },
    token
  });
}

export function fetchReplyApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: URL_FETCH_REPLY,
      variables: params,
    },
    token
  });
}


export function deleteCommentApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: URL_DELETE_COMMENT,
      variables: params,
    },
    token
  });
}
