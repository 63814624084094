import { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addReport,
  addReportSuccess,
  fetchReport,
  fetchReportSuccess,
  fetchReports,
  fetchReportsSuccess,
  deleteReport,
  deleteReportSuccess,
} from '../redux/slices/reportSlice';

import {
  addReportApi,
  fetchReportApi,
  fetchReportsApi,
  deleteReportsApi,
} from '../api/report';

import { toast, errorToast } from '../helpers';
import { showLoading, hideLoading } from '../redux/slices/commonSlice';

function* addReportSaga(action) {
  try {
    if (action.payload.inputs.message) {
      const res = yield call(addReportApi, {
        token: action.payload?.token,
        params: action.payload,
      });

      const { data, errors } = res.data;

      if (data.addReport?.ok) {
        toast('Success!');
        yield put(fetchReportSuccess(data?.addReport?.data));
      } else {
        errorToast('error', `${data?.addReport?.error || 'Errors'}`);
      }
    } else {
      errorToast('error', 'Please input message.');
    }
  } catch (error) {
    yield put(hideLoading(false));
    errorToast(error, error?.message);
  }
}

function* fetchReportSaga(action) {
  try {
    const res = yield call(fetchReportApi, {
      token: action.payload?.token,
      params: action.payload,
    });

    const { data, errors } = res.data;

    yield put(fetchReportSuccess(data?.reports));
  } catch (error) {
    errorToast(error, error?.message);
  }
}

function* fetchReportsSaga(action) {
  try {
    const res = yield call(fetchReportsApi, {
      token: action.payload?.token,
      params: action.payload,
    });

    const { reports } = res.data.data;

    yield put(fetchReportsSuccess(reports));
  } catch (error) {
    console.log(error);
    errorToast(error, error?.message);
  }
}

function* deleteReportSaga(action) {
  try {
    const res = yield call(deleteReportsApi, {
      params: action.payload,
    });

    const { data, errors } = res.data;

    if (data.deleteReport?.ok) {
      yield put(deleteReportSuccess(data?.deleteReport?.data));
      toast('Success!');
    } else {
      if (data.deleteReport?.error) {
        errorToast('error', `${data.deleteReport?.error}`);
      } else {
        errorToast('error', `${errors[0].message}`);
      }
    }
  } catch (error) {
    errorToast(error, error?.message);
  }
}

export default function* reportSaga() {
  yield takeLatest(addReport.type, addReportSaga);
  yield takeLatest(deleteReport.type, deleteReportSaga);
  yield takeEvery(fetchReport.type, fetchReportSaga);
  yield takeEvery(fetchReports.type, fetchReportsSaga);
}
