import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  fetchConfigDomainsApi,
  fetchConfigDomainApi,
  updateConfigDomainApi
} from "../api";

import { 
   addConfigDomain,
   addConfigDomainSuccess,
   updateConfigDomain,
   updateConfigDomainSuccess,
   fetchConfigDomain,
   fetchConfigDomainSuccess,
   fetchConfigDomains,
   fetchConfigDomainsSuccess,
   deleteConfigDomain,
   deleteConfigDomainSuccess
 } from "../redux/slices/configDomainSlice";

function* fetchConfigDomainsSaga(action) {
  try {
    const res = yield call(fetchConfigDomainsApi, { params: action.payload });
    yield put((fetchConfigDomainsSuccess(res?.data?.configDomains)));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* fetchConfigDomainSaga(action) {
  try {
    const res = yield call(fetchConfigDomainApi, { params: action.payload });
    yield put(fetchConfigDomainSuccess(res?.data?.configDomain));
  } catch (error) {
    toast.error("Get errors !");
  }
}

// function* addDomainSaga(action) {
//   try {
//     const res = yield call(addDomainApi, { params: action.payload });

//     const { data, errors } = res;
    
//     if (data?.addDomain?.ok) {
//       toast.success("Created success");
//       yield put(addDomainSuccess(data?.addDomain?.data));
//     } else {
//       if (data.addDomain?.error) {
//         toast.error(`${data.addDomain?.error}`);
//       } else {
//         toast.error(`${errors[0].message}`);
//       }
//     }
//   } catch (error) {
//     toast.error(error?.message);
//   }
// }


// function* deleteDomainSaga(action) {
//   try {
//     const res = yield call(deleteDomainApi, { params: action.payload });

//     const { data, errors } = res;
    
//     if (data.deleteDomain?.ok) {
//       toast.success("Deleted Success");
//       yield put(deleteDomainSuccess(data?.deleteDomain?.data));

//     } else {
//       if (data.deleteDomain?.error) {
//         toast.error(`${data.deleteDomain?.error}`);
//       } else {
//         toast.error(`${errors[0].message}`);
//       }
//     }
//   } catch (error) {
//     toast.error(error?.message);
//   }
// }

function* updateConfigDomainSaga(action) {
  try {
    const res = yield call(updateConfigDomainApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.updateConfigDomain?.ok) {
      toast.success("Updated Success");
      yield put(updateConfigDomainSuccess(data?.updateConfigDomain?.data));
    } else {
      if (data.updateConfigDomain?.error) {
        toast.error(`${data.updateConfigDomain?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchConfigDomains.type, fetchConfigDomainsSaga);
  yield takeEvery(fetchConfigDomain.type, fetchConfigDomainSaga);
  // yield takeLatest(addDomain.type, addDomainSaga);
  // yield takeLatest(deleteDomain.type, deleteDomainSaga);
  yield takeLatest(updateConfigDomain.type, updateConfigDomainSaga);
}
