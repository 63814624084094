// user { id, email, nickName, avatar, provider_pic} 

// import { gql } from "@apollo/client"

export const URL_CREATE_COMMENT = `
  mutation createComment($inputs: InputComments){
    createComment(inputs: $inputs)
    {
      data { 
        id, 
        entryId
        chapter
        commentId
        types
        contents
        likes
        status 
        createdAt
        updatedAt 
      }
      ok
      error
    }
  }
`;

export const URL_UPDATE_COMMENT = `
  mutation updateComment($id: ID!, $inputs: InputComments) {
    updateComment(id: $id, inputs: $inputs){
      data { 
        id, 
        user { id, email, nickName, avatar, provider_pic}  
        entryId
        chapter
        commentId
        types
        contents
        likes
        status 
        createdAt
        updatedAt 
      }
      ok
      error
    }
  }
`;

export const URL_DELETE_COMMENT = `
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      data { 
        id,
        entryId
        chapter
        commentId
        types
        contents
        likes
        status 
        createdAt
        updatedAt  
      }
      ok
      error
    }
  }
`;

export const URL_FETCH_COMMENTS = `
  query comments($inputs: InputComments) {
    comments(inputs: $inputs) {
      docs { 
        id
        user { id, email, nickName, avatar, provider_pic}
        entryId  
        chapter
        commentId
        types
        contents
        likes
        status
        updatedAt
        createdAt
      },
      totalPages,
      limit,
      totalPages
      pagingCounter
      totalDocs
      page
    }
  }
`;

export const URL_FETCH_REPLY = `
  query reply($commentId: ID!, $inputs: InputComments) {
    reply(commentId: $commentId, inputs: $inputs) {
      id
      user { id, email, nickName, avatar, provider_pic}
      entryId  
      chapter
      commentId
      types
      contents
      likes
      status
      updatedAt
      createdAt
    }
  }
`;