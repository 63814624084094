import request from './axiosServices';
import { FETCH_ENTRIES_LIST } from '../graphql/entries';

export function fetchEntriesListApi({ token, params}) {
  return request({
    method: 'POST',
    data: {
      query: FETCH_ENTRIES_LIST,
      variables: params
    }
  });
}


// export function fetchEntriesByIdApi({ token, params}) {
//   return request({
//     method: 'POST',
//     data: {
//       query: FETCH_ENTRIES_BY_ID,
//       variables: params
//     }
//   });
// }