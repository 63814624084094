import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { API_URL_BASE } from "../constants";

const config = {
  baseURL: API_URL_BASE,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  (config) => {
    config.data = config.data || [];

    config.params = config.params || {};

    if (!config.noNeedToken) {
      config.headers = {
        Authorization: `Bearer ${config.token}`,
      };
    }

    if (config.isFormData) {
      config.headers = {
        "Content-Type": "multipart/form-data",
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      Cookies.remove("SKE_SPORT_TOKEN");
      Window.location = "/";
    } else {
      return Promise.reject(error);
    }
  }
);
// use other login 14
export default axiosClient;
