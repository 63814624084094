import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  domains: [],
  domain: null
}

const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    fetchDomains(state, action) {},

    fetchDomainsSuccess(state, action) {
      state.domains = action.payload;
    },

    fetchDomainById(state, action) {
      state.domain = null;
    },

    fetchDomainByIdSuccess(state, action) {
      state.domain = action.payload;
    },

    addDomain(state, action) {
    },

    addDomainSuccess(state, action) {
      state.domains = [action.payload, ...state.domains];
    },

    updateDomain(state, action) {
    },

    updateDomainSuccess(state, action) {
      const index = state.domains?.findIndex(
        (i) => i.id === action.payload?.id
      );

      if (index !== -1) {
        state.domains[index] = action.payload;
      }

      state.domain = action.payload;
    },

    deleteDomain(state, action) {
    },

    deleteDomainSuccess(state, action) {
      state.domains = state?.domains.filter(item => item.id !== action.payload.id);
    }
  },
});

// Actions
export const {
  fetchDomainById,
  fetchDomainByIdSuccess,
  fetchDomains,
  fetchDomainsSuccess,
  addDomain,
  addDomainSuccess,
  updateDomain,
  updateDomainSuccess,
  deleteDomain,
  deleteDomainSuccess
} = domainSlice.actions;

// Reducer
const domainReducer = domainSlice.reducer;
export default domainReducer;
