import { gql } from "@apollo/client";

export const FETCH_ENTRIES_DATA = gql`
  query entriesData($inputs: InputEntriesData) {
    entriesData (inputs: $inputs) {
      docs {
        _id,
        entry_id {
          _id, 
          title,
          alt_title,
          description,
          authors { name },
          genres { name },
          title_normalized,
          adult,
          released_year,
          status,
          thumbnail,
          type,
          created_date,
          modified_date,
          rating,
          rating_votes,
          views
        },
        chapters { name, title, images, index }
        volume_name
      }
      totalPages
      totalDocs
    }
  }
`;
