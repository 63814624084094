import { gql } from '@apollo/client';

export const FETCH_CONFIG_DOMAINS = gql`
  query configDomains($limit: Int, $inputs: InputConfigDomain) {
    configDomains(limit: $limit, inputs: $inputs) {
      domain
      seoTitle
      seoDescription
      og_site_name
      og_type
      og_title
      og_description
      og_url
      genres
      cdn_images
      typeConfig
      page
    }
  }
`;

export const FETCH_CONFIG_DOMAIN = gql`
  query configDomain($id: ID!, $inputs: InputConfigDomain) {
    configDomain(id: $id, inputs: $inputs) {
      id
      domain
      seoTitle
      seoDescription
      og_site_name
      og_type
      og_title
      og_description
      og_url
      page
      genres
      cdn_images
      typeConfig
      status
    }
  }
`;

export const UPDATE_CONFIG_DOMAIN = gql`
  mutation updateConfigDomain($id: ID!, $pages: [InputConfigDomain], $inputs: InputConfigDomainArray) {
    updateConfigDomain(id: $id, inputs: $inputs, pages: $pages) {
      data {
        id
        domain
        seoTitle
        seoDescription
        og_site_name
        og_type
        og_title
        og_description
        og_url
        genres
        cdn_images
        typeConfig
        status
      }
      ok
      error
    }
  }
`;

// export const DELETE_DOMAIN = gql`
//   mutation deleteConfigDomain($id: ID!) {
//     deleteConfigDomain(id: $id) {
//       data {
//         id
//         domain
//         seoTitle
//         seoDescription'
//         genres
//         status
//         updatedAt
//         createdAt
//       }
//       ok
//       error
//     }
//   }
// `;
