import { gql } from '@apollo/client';

export const FETCH_DOMAINS = gql`
  query domains($limit: Int) {
    domains(limit: $limit) {
      id
      domain
      seoTitle
      seoDescription
      status
      updatedAt
      createdAt
    }
  }
`;

export const FETCH_DOMAIN_BY_ID = gql`
  query domain($id: ID!) {
    domain(id: $id) {
      id
      domain
      seoTitle
      seoDescription
      status
      updatedAt
      createdAt
    }
  }
`;

export const ADD_DOMAIN = gql`
  mutation addDomain($inputs: InputDomain) {
    addDomain(inputs: $inputs) {
      data {
        id
        domain
        seoTitle
        seoDescription
        status
        updatedAt
        createdAt
      }
      ok
      error
    }
  }
`;

export const UPDATE_DOMAIN = gql`
  mutation updateDomain($id: ID!, $inputs: InputDomain) {
    updateDomain(id: $id, inputs: $inputs) {
      data {
        id
        domain
        seoTitle
        seoDescription
        status
        updatedAt
        createdAt
      }
      ok
      error
    }
  }
`;
export const DELETE_DOMAIN = gql`
  mutation deleteDomain($id: ID!) {
    deleteDomain(id: $id) {
      data {
        id
        domain
        seoTitle
        seoDescription
        status
        updatedAt
        createdAt
      }
      ok
      error
    }
  }
`;
