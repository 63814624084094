import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchUserAdminListApi } from "../api";
import { userAdminListAction } from "../redux/slices/adminUserSlice";

function* fetchUserAdminListSaga(action) {
  try {
    const res = yield call(fetchUserAdminListApi);
    yield put(userAdminListAction.fetchUserAdminListSuccess(res.data));
  } catch (error) {
    // failedToast(error, errCts.LOGIN_FAILED);
  }
}

export default function* reportSaga() {
  yield takeEvery(userAdminListAction.fetchUserAdminList.type, fetchUserAdminListSaga);
}
