import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  configDomains: [],
  configDomain: null
}

const configDomainSlice = createSlice({
  name: 'configDomain',
  initialState,
  reducers: {
    fetchConfigDomains(state, action) {},

    fetchConfigDomainsSuccess(state, action) {
      state.configDomains = action.payload;
    },

    fetchConfigDomain(state, action) {
      state.configDomain = null;
    },

    fetchConfigDomainSuccess(state, action) {
      state.configDomain = action.payload;
    },

    addConfigDomain(state, action) {
    },

    addConfigDomainSuccess(state, action) {
      state.configDomain = [action.payload, ...state.configDomain];
    },

    updateConfigDomain(state, action) {
    },

    updateConfigDomainSuccess(state, action) {
      state.configDomain = action.payload;
    },

    deleteDomain(state, action) {
    },

    deleteDomainSuccess(state, action) {
      state.domains = state?.domains.filter(item => item.id !== action.payload.id);
    }
  },
});

// Actions
export const {
  fetchConfigDomain,
  fetchConfigDomainSuccess,
  fetchConfigDomains,
  fetchConfigDomainsSuccess,
  addConfigDomain,
  addConfigDomainSuccess,
  updateConfigDomain,
  updateConfigDomainSuccess,
  deleteConfigDomain,
  deleteConfigDomainSuccess
} = configDomainSlice.actions;

// Reducer
const configDomainReducer = configDomainSlice.reducer;
export default configDomainReducer;
