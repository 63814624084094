import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  reports: [],
  report: {
    id: '',
  },
};

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchReports(state, action) {
      // state.reports = null;
    },
    fetchReportsSuccess(state, action) {
      state.reports = action.payload;
    },

    fetchReport(state, action) {
      state.report = null;
    },
    fetchReportSuccess(state, action) {
      state.report = action.payload;
    },

    addReport(state, action) {
      state.report = null;
    },
    addReportSuccess(state, action) {
      state.report = action.payload;
    },

    deleteReport(state, action) {
      // state.report = null;
    },
    deleteReportSuccess(state, action) {
      state.report = null;
    },
  },
});

// Actions
export const {
  fetchReports,
  fetchReportsSuccess,
  fetchReport,
  fetchReportSuccess,
  addReport,
  addReportSuccess,
  deleteReport,
  deleteReportSuccess,
} = reportSlice.actions;

// Reducer
const reportsReducer = reportSlice.reducer;

export default reportsReducer;
