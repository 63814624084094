import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  entriesData: {},
}

const entriesDataSlice = createSlice({
  name: 'entriesData',
  initialState,
  reducers: {
    fetchEntriesData(state, action) {},
    fetchEntriesDataSuccess(state, action) {
      state.entriesData = action.payload;
    }
  },
});

// Actions
export const {
  fetchEntriesData,
  fetchEntriesDataSuccess
} = entriesDataSlice.actions;

// Reducer
const entriesDataReducer = entriesDataSlice.reducer;
export default entriesDataReducer;