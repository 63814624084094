import { apolloClient } from "../graphql/apolloClient";

import {
  ADD_DOMAIN,
  FETCH_DOMAINS,
  UPDATE_DOMAIN,
  FETCH_DOMAIN_BY_ID,
  DELETE_DOMAIN
} from "../graphql/domain";

export function fetchDomainsApi({token, params}) {
  return apolloClient.query({
    query: FETCH_DOMAINS,
    variables: params
  });
}

export function fetchDomainByIdApi({ params }) {
  return apolloClient.query({
    query: FETCH_DOMAIN_BY_ID,
    variables: params,
  });
}

export function addDomainApi({ token, params }) {
  return apolloClient.mutate({
    mutation: ADD_DOMAIN,
    variables: params,
  });
}

export function updateDomainApi({ token, params }) {
  return apolloClient.mutate({
    mutation: UPDATE_DOMAIN,
    variables: params,
  });
}

export function deleteDomainApi({ token, params }) {
  return apolloClient.mutate({
    mutation: DELETE_DOMAIN,
    variables: params,
  });
}
