import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  entriesSetting: null
}

const entriesSettingSlice = createSlice({
  name: 'entriesSetting',
  initialState,
  reducers: {
    addEntriesSetting(state, action) {
    },

    addEntriesSettingSuccess(state, action) {
      state.entriesSetting = action.payload;
    }
  },
});

// Actions
export const {
  addEntriesSetting,
  addEntriesSettingSuccess
} = entriesSettingSlice.actions;

// Reducer
const entriesSettingReducer = entriesSettingSlice.reducer;
export default entriesSettingReducer;
