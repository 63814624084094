import { all } from 'redux-saga/effects';

import userAdminSaga from './userAdmin';
import userSaga from './userSaga';
import matchesDateSaga from './matchesDateSaga';
import competitionSaga from './competitionSaga';
import teamsSaga from './teamsSaga';
import leaguesSaga from './leaguesSaga';
import entriesDataSaga from './entriesDataSaga';
import generalSaga from './generalSaga';
import historyPlanSaga from './historyPlan';
import paymentSaga from './paymentSaga';
import entriesSaga from './entriesSaga';
import entriesSettingSaga from './entriesSettingSaga';
import commentSaga from './commentSaga';
import domainSaga from './domainSaga';
import configDomainSaga from './configDomainSaga';
import reportSaga from './reportSaga';

export default function* rootSaga() {
  yield all([
    userAdminSaga(),
    userSaga(),
    matchesDateSaga(),
    competitionSaga(),
    teamsSaga(),
    leaguesSaga(),
    entriesDataSaga(),
    generalSaga(),
    historyPlanSaga(),
    paymentSaga(),
    entriesSaga(),
    entriesSettingSaga(),
    commentSaga(),
    domainSaga(),
    configDomainSaga(),
    reportSaga(),
  ]);
}
