import request from './axiosServices';

import {
  URL_ADD_REPORT,
  FETCH_REPORT,
  FETCH_REPORTS,
  DELETE_REPORT,
} from '../graphql/report';

export function addReportApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: URL_ADD_REPORT,
      variables: params,
    },
    token,
  });
}

export function fetchReportApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: FETCH_REPORT,
      variables: params,
    },
    token,
  });
}

export function fetchReportsApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: FETCH_REPORTS,
      variables: params,
    },
    token,
  });
}

export function deleteReportsApi({ token, params }) {
  return request({
    method: 'POST',
    data: {
      query: DELETE_REPORT,
      variables: params,
    },
    token,
  });
}
