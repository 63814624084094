import { apolloClient } from '../graphql/apolloClient';
// import request from './axiosServices';
import { ADD_ENTRIES_SETTING } from '../graphql/entriesSetting';

export function addEntriesSettingApi({ token, params}) {
  return apolloClient.mutate({
    mutation: ADD_ENTRIES_SETTING,
    variables: params,
  });
}


// export function fetchEntriesByIdApi({ token, params}) {
//   return request({
//     method: 'POST',
//     data: {
//       query: FETCH_ENTRIES_BY_ID,
//       variables: params
//     }
//   });
// }