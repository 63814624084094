import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  addEntriesSettingApi
} from "../api";

import { 
  addEntriesSetting,
  addEntriesSettingSuccess
} from "../redux/slices/entriesSettingSlice";

import { 
  addEntriesSettingListSuccess
} from "../redux/slices/entriesSlice";

function* addEntriesSettingSaga(action) {
  try {
    const res = yield call(addEntriesSettingApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data?.addEntriesSetting?.ok) {
      toast.success("Updated success");
      yield put(addEntriesSettingSuccess(data?.addEntriesSetting?.data));
      yield put(addEntriesSettingListSuccess(data?.addEntriesSetting?.data));
    } else {
      if (data.addEntriesSetting?.error) {
        toast.error(`${data.addEntriesSetting?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeLatest(addEntriesSetting.type, addEntriesSettingSaga);
}
