
export const FETCH_ENTRIES_LIST = `
  query entries($inputs: InputEntries) {
    entries(inputs: $inputs){
      docs { 
        _id, 
        title,
        alt_title,
        description,
        title_normalized,
        adult,
        released_year,
        status,
        thumbnail,
        type,
        authors { name },
        genres { name, uid },
        created_date,
        modified_date,
        rating,
        rating_votes,
        entries_data { _id, volume_name, chapters { name, title, images, index } }
        entries_setting { _id, premium, entryId, isHide }
        views
      },
      totalPages,
      limit,
      totalPages
      pagingCounter
      totalDocs
      page
    }
  }
`;

export const FETCH_ENTRIES_BY_ID = `
  query entry($id: ID, $inputs: InputEntries) {
    entry (_id: $id, inputs: $inputs ){
      _id, 
      title,
      alt_title,
      description,
      title_normalized,
      adult,
      released_year,
      status,
      thumbnail,
      type,
      authors { name },
      genres { name },
      created_date,
      modified_date,
      rating,
      rating_votes,
      views,
      entries_data { _id, chapters {name, title, index, images}, volume_name }
    }
  }
`;
