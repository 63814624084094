export const URL_ADD_REPORT = `
  mutation addReport($inputs: InputReport){
    addReport(inputs: $inputs)
    {
      data { id, appName, storyId, storyName, message, rating, status, createdAt, updatedAt },
      ok,
      error
    }
  }
`;

export const DELETE_REPORT = `
  mutation deleteReport($id: ID!) {
    deleteReport(id: $id) {
      data { id, appName, storyId, storyName, message, rating, status, createdAt, updatedAt },
      ok,
      error
    }
  }
`;

export const FETCH_REPORTS = `
  query reports($inputs: InputReport) {
    reports(inputs: $inputs) {
      id, appName, rating, storyId, storyName, message, status, createdAt, updatedAt
    }
  }
`;

export const FETCH_REPORT = `
  query report($inputs: InputReport) {
    report(inputs: $inputs) {
      id, appName, rating, storyId, storyName, message, status, createdAt, updatedAt
    }
  }
`;
